<template>
  <div class="myhome">
    <div class="top-fast-mp">
      <el-alert class="mp-alert" v-if="!plats && plats.length == 0"
        title="还没有授权的公众号或小程序呢~"
        type="warning"
        effect="dark"
        show-icon>
      </el-alert>
      <div class="mp-fast-btn">
        <a href="javascript:;" @click="onNewAppClick" type="primary">快速添加公众号或小程序</a>
      </div>
    </div>
    <panel-group @handleSetLineChartData="handleSetLineChartData"
      :newPayEnd="newPayEnd"
      :monthEnd="monthEnd"
      :yearEnd="yearEnd"
      :totalEnd="totalEnd"
    />
    <div class="plats" v-if="global.login && global.login.plats">
      <el-card :class="'plat-card plat-card_' + (global.login.platid == ob.id)" 
        shadow="always" 
        v-for="ob in global.login.plats" :key="ob.id"
        :body-style="{ padding: '0px' }"        
      >
        <div class="plat-item" @click="onPlatItemClick(ob)">
          <img class="plat-img" :src="ob.head_img">
          <div class="plat-ctx">
            <div class="plat-name">{{ob.nick_name}}</div>
            <div class="plat-type">{{transPlatType(ob.auth_type)}}
              
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="cur-plat" v-if="curPlat">
      <div class="seg-title">当前应用：{{curPlat.nick_name}} </div>
      <div class="cur-imgs">
        <div class="cur-img">
          <img :src="curPlat.head_img">
          <div class="cur-img-text">应用头像</div>
        </div>
        <div class="cur-img">
          <!-- <img :src="curPlat.qrcode_mp"> -->
          <img :src="curPlat.qrcode_url">
          <div class="cur-img-text">应用二维码</div>
        </div>
        <div class="cur-txt">
          <div class="cur-comp">{{curPlat.principal_name}}</div>
          <div class="cur-username">{{curPlat.user_name}}</div>
          <div class="cur-wxh">{{curPlat.alias}}</div>
        </div>
      </div>
    </div>
    <!-- <div id="lineChart"></div>     -->
    <div v-if="refreshFlag"></div>
  </div>
</template>

<script>
import encryptUtil from '@/utils/encrypt.js'
import PanelGroup from './components/PanelGroup'
var self = null
export default {
  // name: 'MyHome',
  components: {
    PanelGroup
  },
  data() {
    return {
      refreshFlag: false,
      newAppUrl: '',
      plats: [],
      curPlat: false,
      // lineChartData: lineChartData.newVisitis
      // stat: {},
      newPayEnd: 0,
      monthEnd: 0,
      yearEnd: 0,
      totalEnd: 0,
      day30: {labels: [], cc30: [], val30: []},
      yearStat: {cc: 0, value: 0},
      monthStat:  {cc: 0, value: 0},
      myChart: null,      
    }
  },
  created (opt) {
    self = this;    
    this.bus.$on('plats_loaded', ()=>{
      this.refresh();
    })  
    this.bus.$on('plat_changed', this.eventPlatChanged)    
    this.eventPlatChanged(this.global.login.plat);    
  },
  mounted(){

  },
  destroyed(){
    this.bus.$off('plats_loaded')
    this.bus.$off('plat_changed')
  },
  methods: {
    refresh() {
      this.refreshFlag = !this.refreshFlag;
      this.$nextTick(()=>{
        this.refreshFlag = !this.refreshFlag;
      })
    },
    eventPlatChanged(plat) {
      this.curPlat = plat;  
      this.newPayEnd = 0;    
      this.monthEnd = 0;
      this.yearEnd = 0;
      this.totalEnd = 0;
      this.loadData();
    },
    async loadData() {
      // console.log('load home data...')
      var pm = {
        appid : this.curPlat.appid
      }
      this.post('api/me/summary', pm, res=>{
        if (res.code != 0) {
          this.toast('获取数据失败！\r\n' + res.msg);
          return;
        }
        // console.log(res)
        var cumlist = res.cum.list;
        if (cumlist) {
          this.newPayEnd = cumlist[cumlist.length - 1].cumulate_user
        }
        var sumlist = res.sum.list   
        if (sumlist) {     
          var sumyes = sumlist[sumlist.length -1];
          this.monthEnd = sumyes.new_user
          this.yearEnd = sumyes.cancel_user
          this.totalEnd = 0;
          sumlist.forEach(e=>{
            this.totalEnd += e.new_user
            this.totalEnd -= e.cancel_user
          })   
        }     
      })
    },
    onNewAppClick() {
      // this.dialog({
      //   title: '',
      //   content: '',
      // })
      // const key = this.global.encrypt_key
      // var token = this.global.token.replace('Bearer ', '')
      // console.log(token);
      // var etoken = encryptUtil.encrypt(token, key);
      // console.log(etoken)
      // etoken = encodeURIComponent(etoken)
      // console.log(etoken)
      // this.post('/new_app', {etoken}, res=>{
      //   if (res.code != 0) {
      //     this.toast('生成授权链接失败，请稍后重试~');
      //     return
      //   }
      //   this.newAppUrl = res.data.url;
      //   console.log(this.newAppUrl)
      //   window.location.href = this.newAppUrl;
      // })
      const key = this.global.encrypt_key
      var username = this.global.login.username;
      console.log(username)
      
      var eusername = encryptUtil.encrypt(username, key);
      console.log(eusername)
      eusername = encodeURIComponent(eusername)      
      console.log(eusername)      
      var url = this.global.api + '/home/new_app?eur=' + eusername
      window.open(url)
      // window.location.href = url;
    },
    handleSetLineChartData(e) {
      console.log(e)
    },
    transPlatType(authType) {
      switch(authType) {
        case 1:
          return '公众号'
        case 2: 
          return '小程序'
        default:
          return '未知类型'
      }
    },
    onPlatItemClick(plat) {
      this.$emit('change_plat', plat.id)
    },
  } //--methods
}
</script>

<style scoped lang="scss">
.myhome {
  margin-top: 0px;
}

#lineChart {
  width: 100%;
  height: 45em;
}
.plats {
  display: flex;
  flex-wrap: wrap;

  .plat-card_true {      
    background-color: $sel-bg;
  }
  .plat-card:hover {
    border: 1px solid $primary;
  }
  .plat-card {
    width: 300px;
    // height: 90px;
    margin: 5px;
    cursor: pointer;
    
    .plat-item {
      display: flex;   
      padding: 10px;         
    }
    
    
    .plat-img {
      width: 45px;
      height: 45px;
      border-radius: 45px;
      border: 1px solid #cbcbcb;
    }

    .plat-ctx {
      margin-left: 10px;

      .plat-name {
        font-size: 1.25em;
        font-weight: 700;
      }
      .plat-type {
        text-align: left;
        color: gray;
      }
    }
  }
}

.cur-plat {
  .seg-title {
    text-align: left;
    margin-top: 1em;
    margin-left: 1em;
  }
  .cur-imgs {
    display: flex;    
  }
  .cur-imgs img {
    width: 160px;
  }
  .cur-txt {
    margin-top: 2em;

    .cur-comp {
      font-size: 1.2em;
      font-weight: 700;
    }
    .cur-username {
      color: gray;
      font-size: 1.1em;
      margin-top: 0.5em;
    }
  }
}
</style>
